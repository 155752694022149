@import '../../../../vars';


#camera-filter-list {
    width: 100%;
    z-index: 3;
    padding: 4rem;
    background: rgba(black, 0.8);
    overflow-x: scroll;
    direction: ltr;

    .filter-list-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-width: min-content;

        .filter-item {
            width: 10rem;
            height: 10rem;
            margin: 0 2rem;
            border-radius: 5px;
            background: $secondary-color;
            color: white;
            font-size: 3.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 4rem;
            }
    
            &.active {
                border: 1px solid white;
                background: $primary-color;
                transform: scale(1.1);
            }
        }
    }
}