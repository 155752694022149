@import '../../vars';

#camera {
    #camera-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        canvas {
            width: 100% !important;
            height: auto !important;
            max-width: 100% !important;
            max-height: 100% !important;
            touch-action: none;
        }
    }

    .camera-top-botton {
        position: fixed;
        padding: 4rem 0;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        background: linear-gradient(to bottom, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%);
        justify-content: center;
        align-items: center;
        display: none; 
        
        &.active {
            display: flex;
        }

        & > div {
            margin: 0 3rem;
        }
    }

    .camera-bottom-button {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        width: 100%;
        background: linear-gradient(to top, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%);
        
        .buttons {
            display: none;
            padding: 8rem 5% 4rem;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            
            &.active {
                display: flex;
            }

            .buttons-group {
                width: 30%;
                display: flex;
                align-items: center;

                &.buttons-group-right {
                    justify-content: space-between;
                }

                &.buttons-group-left {
                    flex-direction: row-reverse;
                }
            }   
        }
    }
}