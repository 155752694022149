@import '../../../_vars';

.popup-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background: rgba(white, .5);

    .popup-area {
        width: 93%;
        background: rgba(black, .9);
        padding: 3.2rem;
        border-radius: 10px;
    }

    .popup-close {
        width: 9%;

        img {
            width: 100%;
        }

        &.show {
            display: block;
        }

        &.hide {
            display: none;
        }
    }
}