@import '../../../../vars';

#camera-copies-wrapper {
    color: $secondary-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .copies-btns-wrapper {
        font-size: 17.33rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            text-shadow: 0 0 8px rgba($secondary-color, .5);
        }

        .copies-number {
            line-height: 1;
        }

        .copies-btn {
            width: 11rem;
            font-size: 85%;

            &.disabled {
                opacity: .7;
            }
        }

        .copies-dec {
            position: relative;
            top: -1.3rem;
        }
    }

    .copies-number {
        line-height: 1;
    }   

    .copies-des {
        font-weight: lighter;
        font-size: 4rem;
    }
}