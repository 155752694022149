@import '../../../../vars';

#camera-pic-btn {
    border: 2.66rem solid rgba($secondary-color, .5);
    border-radius: 100%;
    
    & > div {
        border-radius: 100%;
        background: $secondary-color;
        width: 14rem;
        height: 14rem;
    }
}