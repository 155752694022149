@import '../../../_vars';

.main-screen {
    width: 100%;
    height: 100%;
    background-image: url('./images/bg.png');
    background-size: 5%;
    background-repeat: repeat;
    background-position: left top;
    padding: 4%;
}

.main-screen-wrapper {
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    position: relative;
    padding: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main-screen-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}

.selpho-logo {
    width: 16.13rem;
}

.termsofuse {
    font-size: 4.03rem;
    color: black;
    text-decoration: none;
}