@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
.main-screen {
  width: 100%;
  height: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAN8AAACfCAYAAAB5oQxAAAACfElEQVR4nO3XsY0TARBA0VlkiKED5xgJUQHUQUQLVEQTJDRBYnLTAQGgky5YhISuAMD6e3vvVTAzu19rL+v5uA730dfldDl6cvfXo4d+AKiIDyLig4j4ICI+iIgPIuKDiPggIj6IiA8i4oOI+CAiPoiIDyLig4j4ICI+iIgPIuKDiPggIj6IiA8i4oOI+CAiPoiIDyLig4j4ICI+iIgPIuKDiPggIj6IiA8i4oOI+CAiPoiIDyLig4j4ICI+iIgPIuKDiPggIj6ILOv5uDr+vXQ7Mz93vuPb5XT5uIE5ruIwM+92uNdv72fmxQbmuJbvM/Nsn6vdebyROa7isJwuH3a416zn4+udx3ezgRn4B/7zQUR8EBEfRMQHEfFBRHwQER9ExAcR8UFEfBARH0TEBxHxQUR8EBEfRMQHEfFBRHwQER9ExAcR8UFEfBARH0TEBxHxQUR8EBEfRMQHEfFBRHwQER9ExAcR8UFEfBARH0TEBxHxQUR8EBEfRMQHEfFBRHwQER9ExAcR8UFEfBARH0TEBxHxQUR8EBEfRMQHEfFB5LDjw3+amW8bmONanszMq32udufNej6+3Mgs/92ys30ejD8v5eed7/tlZp5vYI6r8LMTIuKDiPggIj6IiA8i4oOI+CAiPoiIDyLig4j4ICI+iIgPIuKDiPggIj6IiA8i4oOI+CAiPoiIDyLig4j4ICI+iIgPIuKDiPggIj6IiA8i4oOI+CAiPoiIDyLig4j4ICI+iIgPIuKDiPggIj6IiA8i4oOI+CAiPoiIDyLig4j4ICI+iIgPIuKDiPggIj6IiA8i4oPIYT0fn+74+LfL6fJjA3Pwd/b7cZiZX503IY3MPTcGAAAAAElFTkSuQmCC);
  background-size: 5%;
  background-repeat: repeat;
  background-position: left top;
  padding: 4%; }

.main-screen-wrapper {
  width: 100%;
  height: 100%;
  background-color: #ffd321;
  position: relative;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }

.main-screen-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse; }

.selpho-logo {
  width: 16.13rem; }

.termsofuse {
  font-size: 4.03rem;
  color: black;
  text-decoration: none; }

.popup-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5); }
  .popup-screen .popup-area {
    width: 93%;
    background: rgba(0, 0, 0, 0.9);
    padding: 3.2rem;
    border-radius: 10px; }
  .popup-screen .popup-close {
    width: 9%; }
    .popup-screen .popup-close img {
      width: 100%; }
    .popup-screen .popup-close.show {
      display: block; }
    .popup-screen .popup-close.hide {
      display: none; }

.open-camera-wrapper {
  text-align: center; }

.event-title {
  font-weight: 300;
  font-size: 6.66rem;
  margin-bottom: 4rem; }

.open-camera {
  width: 47.73rem;
  height: 47.73rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed4466;
  border-radius: 100%;
  padding: 5.2rem;
  margin: 0 auto; }

.open-camera > div {
  width: 100%;
  height: 100%;
  border: 2px solid white;
  border-radius: 100%;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear; }

.open-camera:hover > div {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 0; }

#page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  #page404 .page404-icon {
    width: 27.2rem;
    margin-bottom: 3rem; }
  #page404 .page404-title {
    color: #ed4466;
    font-size: 12rem;
    font-weight: bold;
    text-align: center;
    line-height: .95; }

#horizontal-screen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #ed4466;
  color: white;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 3vw; }
  @media screen and (orientation: landscape) {
    #horizontal-screen {
      display: flex; } }

#upload-popup .upload-progress-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0; }
  #upload-popup .upload-progress-wrapper .upload-progress {
    width: 47rem;
    height: 47rem;
    background: #ed4466;
    border-radius: 100%;
    border: 3px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    #upload-popup .upload-progress-wrapper .upload-progress .onprogress-icon {
      width: 14.26rem; }
    #upload-popup .upload-progress-wrapper .upload-progress .onprogress-text {
      color: white;
      font-size: 13.2rem;
      font-weight: bold; }
  #upload-popup .upload-progress-wrapper .upload-description {
    color: #ed4466;
    font-weight: bold;
    font-size: 5.33rem;
    margin-top: 8.6rem;
    padding: 0 20rem;
    text-align: center;
    line-height: 1.25; }

#upload-popup .upload-success-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 10rem; }
  #upload-popup .upload-success-wrapper .onsuccess-icon {
    width: 37rem; }
  #upload-popup .upload-success-wrapper .onsuccess-description {
    margin-top: 3rem;
    color: #ffd321;
    font-size: 4.8rem;
    text-align: center; }
    #upload-popup .upload-success-wrapper .onsuccess-description strong {
      font-size: 5.33rem;
      display: block;
      letter-spacing: 0.09rem; }
  #upload-popup .upload-success-wrapper .onsuccess-btn {
    margin-top: 3rem;
    font-size: 5.33rem;
    text-align: center;
    color: white;
    background: #ed4466;
    padding: 2rem 0 2.1rem;
    border-radius: 10px; }
  #upload-popup .upload-success-wrapper .onsuccess-image-counter {
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
    display: none; }
    #upload-popup .upload-success-wrapper .onsuccess-image-counter.show {
      display: flex; }
    #upload-popup .upload-success-wrapper .onsuccess-image-counter .image-counter-icon {
      width: 8rem;
      margin-left: 2rem; }
    #upload-popup .upload-success-wrapper .onsuccess-image-counter .image-counter-text {
      font-size: 4rem;
      color: #ffd321; }

#limit-popup .limit-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0 10rem; }
  #limit-popup .limit-wrapper .selpho-icon {
    width: 25.22rem; }
  #limit-popup .limit-wrapper .event-title {
    color: #ed4466;
    font-size: 5.33rem;
    font-weight: normal;
    margin: 2rem 0; }
  #limit-popup .limit-wrapper .limit-title {
    color: #ffd321;
    font-size: 13.6rem;
    font-weight: bold;
    text-align: center;
    line-height: .9;
    margin-bottom: 2rem; }
  #limit-popup .limit-wrapper .limit-subtitle {
    color: #ed4466;
    font-size: 4rem; }

#error-popup .error-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0 10rem; }
  #error-popup .error-wrapper .error-icon {
    width: 30.93rem; }
  #error-popup .error-wrapper .error-title {
    font-weight: bold;
    font-size: 13.6rem;
    text-align: center;
    color: #ffd321;
    padding: 0 5rem;
    line-height: .95;
    margin: 3rem 0; }
  #error-popup .error-wrapper .error-subtitle {
    color: #ed4466;
    font-weight: normal;
    font-size: 4rem;
    text-align: center; }

#blocked-popup .blocked-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0 10rem; }
  #blocked-popup .blocked-wrapper .blocked-icon {
    width: 30.93rem; }
  #blocked-popup .blocked-wrapper .blocked-title {
    font-weight: bold;
    font-size: 13.6rem;
    text-align: center;
    color: #ffd321;
    padding: 0 5rem;
    line-height: .95;
    margin: 3rem 0; }
  #blocked-popup .blocked-wrapper .blocked-subtitle {
    color: #ed4466;
    font-weight: normal;
    font-size: 4rem;
    text-align: center; }

#camera-pic-btn {
  border: 2.66rem solid rgba(237, 68, 102, 0.5);
  border-radius: 100%; }
  #camera-pic-btn > div {
    border-radius: 100%;
    background: #ed4466;
    width: 14rem;
    height: 14rem; }

#camera-flip-btn {
  width: 11rem; }
  #camera-flip-btn img {
    width: 100%; }

#camera-upload-btn {
  width: 10rem; }
  #camera-upload-btn img {
    width: 100%; }

#camera-filter-btn {
  width: 12rem; }
  #camera-filter-btn img {
    width: 100%; }

#camera-filter-list {
  width: 100%;
  z-index: 3;
  padding: 4rem;
  background: rgba(0, 0, 0, 0.8);
  overflow-x: scroll;
  direction: ltr; }
  #camera-filter-list .filter-list-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content; }
    #camera-filter-list .filter-list-wrapper .filter-item {
      width: 10rem;
      height: 10rem;
      margin: 0 2rem;
      border-radius: 5px;
      background: #ed4466;
      color: white;
      font-size: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase; }
      #camera-filter-list .filter-list-wrapper .filter-item:first-child {
        margin-left: 0; }
      #camera-filter-list .filter-list-wrapper .filter-item:last-child {
        margin-right: 4rem; }
      #camera-filter-list .filter-list-wrapper .filter-item.active {
        border: 1px solid white;
        background: #ffd321;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }

#camera-submit-btn {
  font-size: 5rem;
  color: white;
  background: #ed4466;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-radius: 4px;
  box-shadow: 0 0 9px 1px rgba(237, 68, 102, 0.5); }
  #camera-submit-btn .submit-icon {
    width: 7rem;
    margin-left: 1rem; }

#camera-trash-btn {
  width: 10rem; }
  #camera-trash-btn img {
    width: 100%; }

#camera-copies-wrapper {
  color: #ed4466;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  #camera-copies-wrapper .copies-btns-wrapper {
    font-size: 17.33rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold; }
    #camera-copies-wrapper .copies-btns-wrapper > div {
      display: flex;
      justify-content: center;
      align-items: center;
      text-shadow: 0 0 8px rgba(237, 68, 102, 0.5); }
    #camera-copies-wrapper .copies-btns-wrapper .copies-number {
      line-height: 1; }
    #camera-copies-wrapper .copies-btns-wrapper .copies-btn {
      width: 11rem;
      font-size: 85%; }
      #camera-copies-wrapper .copies-btns-wrapper .copies-btn.disabled {
        opacity: .7; }
    #camera-copies-wrapper .copies-btns-wrapper .copies-dec {
      position: relative;
      top: -1.3rem; }
  #camera-copies-wrapper .copies-number {
    line-height: 1; }
  #camera-copies-wrapper .copies-des {
    font-weight: lighter;
    font-size: 4rem; }

#camera-Sticker-btn {
  width: 10rem; }
  #camera-Sticker-btn img {
    width: 100%; }

.sticker-item {
  width: 27%;
  height: 50%;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .sticker-item.loading .loading-image {
    display: block; }
  .sticker-item.loading .sticker-image {
    display: none; }
  .sticker-item .loading-image {
    width: 30%;
    display: none; }
  .sticker-item .sticker-image {
    width: 100%;
    max-height: 100%; }

#camera-sticker-list {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 4;
  height: 40%;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding: 10% 0 0; }
  #camera-sticker-list .stickers-sep {
    width: 30%;
    height: 3px;
    background: #ffd321;
    margin: 0 auto; }
  #camera-sticker-list .stickers-wrapper {
    overflow-y: auto;
    height: 80%;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5% 10% 10%; }

#camera-download-btn {
  width: 6rem; }
  #camera-download-btn img {
    width: 100%; }

#camera #camera-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }
  #camera #camera-wrapper canvas {
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 100% !important;
    touch-action: none; }

#camera .camera-top-botton {
  position: fixed;
  padding: 4rem 0;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
  justify-content: center;
  align-items: center;
  display: none; }
  #camera .camera-top-botton.active {
    display: flex; }
  #camera .camera-top-botton > div {
    margin: 0 3rem; }

#camera .camera-bottom-button {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 100%;
  background: -webkit-linear-gradient(bottom, white 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%); }
  #camera .camera-bottom-button .buttons {
    display: none;
    padding: 8rem 5% 4rem;
    justify-content: space-around;
    align-items: center;
    width: 100%; }
    #camera .camera-bottom-button .buttons.active {
      display: flex; }
    #camera .camera-bottom-button .buttons .buttons-group {
      width: 30%;
      display: flex;
      align-items: center; }
      #camera .camera-bottom-button .buttons .buttons-group.buttons-group-right {
        justify-content: space-between; }
      #camera .camera-bottom-button .buttons .buttons-group.buttons-group-left {
        flex-direction: row-reverse; }

#event-closed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center; }
  #event-closed .balloon-image {
    width: 17.33rem; }
  #event-closed .title {
    font-size: 11.86rem;
    font-weight: bold;
    color: #ed4466;
    padding: 1rem; }

#event-finished .finished-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5rem; }
  #event-finished .finished-wrapper .finished-image {
    width: 27.2rem;
    margin-bottom: 3rem; }
  #event-finished .finished-wrapper .finished-title {
    color: #ed4466;
    font-size: 12rem;
    line-height: .95;
    font-weight: bold;
    text-align: center; }

#event-waiting .waiting-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5rem; }
  #event-waiting .waiting-wrapper .waiting-image {
    width: 23.2rem;
    margin-bottom: 3rem; }
  #event-waiting .waiting-wrapper .waiting-title {
    color: #ed4466;
    font-size: 12rem;
    line-height: .95;
    font-weight: bold;
    text-align: center; }

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box; }

html, body, #root, #main {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 1vw;
  font-family: 'Open Sans Hebrew', sans-serif; }

html {
  direction: rtl; }

.hide {
  display: none; }

