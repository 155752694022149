@import '../../vars';

#limit-popup {
    .limit-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3rem 0 10rem;

        .selpho-icon {
            width: 25.22rem;
        }

        .event-title {
            color: $secondary-color;
            font-size: 5.33rem;
            font-weight: normal;
            margin: 2rem 0;
        }

        .limit-title {
            color: $primary-color;
            font-size: 13.6rem;
            font-weight: bold;
            text-align: center;
            line-height: .9;
            margin-bottom: 2rem;
        }

        .limit-subtitle {
            color: $secondary-color;
            font-size: 4rem;
        }
    }
}