@import '../../../../vars';

#camera-submit-btn {
    font-size: 5rem;
    color: white;
    background: $secondary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-radius: 4px;
    box-shadow: 0 0 9px 1px rgba($secondary-color, .5);

    .submit-icon {
        width: 7rem;
        margin-left: 1rem;
    }
}