@import '../../../vars';

#event-closed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    text-align: center;
    
    .balloon-image {
        width: 17.33rem;
    }
    
    .title {
        font-size: 11.86rem;
        font-weight: bold;
        color: $secondary-color;
        padding: 1rem;
    }
}