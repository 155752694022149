@import '../../../../vars';

#camera-sticker-list {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 4;
    height: 40%;
    background: rgba(black, .8);
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 10% 0 0;
    
    .stickers-sep {
        width: 30%;
        height: 3px;
        background: $primary-color;
        margin: 0 auto;
    }
    
    .stickers-wrapper {
        overflow-y: auto;
        height: 80%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5% 10% 10%;
    }    
}