@import '../../vars';

#error-popup {
    .error-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3rem 0 10rem;

        .error-icon {
            width: 30.93rem;
        }

        .error-title {
            font-weight: bold;
            font-size: 13.6rem;
            text-align: center;
            color: $primary-color;
            padding: 0 5rem;
            line-height: .95;
            margin: 3rem 0;
        }

        .error-subtitle {
            color: $secondary-color;
            font-weight: normal;
            font-size: 4rem;
            text-align: center;
        }
    }   
}