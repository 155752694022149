@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import '../../vars';

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
    box-sizing: border-box;
}

html, body, #root, #main {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 1vw;
    font-family: 'Open Sans Hebrew', sans-serif;
}

html {
    direction: rtl;
}

.hide {
    display: none;
}