@import '../../vars';

#upload-popup {    
    .upload-progress-wrapper {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10rem 0;
    
        .upload-progress {
            width: 47rem;
            height: 47rem;
            background: $secondary-color;
            border-radius: 100%;
            border: 3px solid white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .onprogress-icon {
                width: 14.26rem;
            }

            .onprogress-text {
                color: white;
                font-size: 13.2rem;
                font-weight: bold;
            }
        }

        .upload-description {
            color: $secondary-color;
            font-weight: bold;
            font-size: 5.33rem;
            margin-top: 8.6rem;
            padding: 0 20rem;
            text-align: center;
            line-height: 1.25;
        }
    }

    .upload-success-wrapper {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0 10rem;

        .onsuccess-icon {
            width: 37rem;
        }

        .onsuccess-description {
            margin-top: 3rem;
            color: $primary-color;
            font-size: 4.8rem;
            text-align: center;
            
            strong {
                font-size: 5.33rem;
                display: block;
                letter-spacing: 0.09rem;
            }
        }

        .onsuccess-btn {
            margin-top: 3rem;
            font-size: 5.33rem;
            text-align: center;
            color: white;
            background: $secondary-color;
            padding: 2rem 0 2.1rem;
            border-radius: 10px;
        }

        .onsuccess-image-counter {
            margin-top: 3rem;
            justify-content: center;
            align-items: center;
            display: none;

            &.show {
                display: flex;
            }

            .image-counter-icon {
                width: 8rem;
                margin-left: 2rem;
            }

            .image-counter-text {
                font-size: 4rem;
                color: $primary-color;
            }
        }
    }
}