@import '../../vars';

#page404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .page404-icon {
        width: 27.2rem;
        margin-bottom: 3rem;
    }

    .page404-title {
        color: $secondary-color;
        font-size: 12rem;
        font-weight: bold;
        text-align: center;
        line-height: .95;
    }
}