@import '../../../vars';

#event-waiting {
    .waiting-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 5rem;
    
        .waiting-image {
            width: 23.2rem;
            margin-bottom: 3rem;
        }
    
        .waiting-title {
            color: $secondary-color;
            font-size: 12rem;
            line-height: .95;
            font-weight: bold;
            text-align: center;
        }
    }
}